<script lang="ts">
	import Layout from "../components/Layout.svelte";
	import Link from "../components/Link.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";
</script>

<Layout>
	<section class="container mt-20 md:mt-32">
		<h1 class="text-center">{nbspify("Stránka nenalezena")}</h1>
		<div class="mt-8 text-center">
			<p>{nbspify("Je nám líto, ale požadovaná stránka nebyla nalezena.")}</p>
			<p>
				{nbspify(
					"Ujistěte se, že jste neudělali chybu v URL adrese. Je možné, že byla stránka přemístěna nebo odstraněna.",
				)}
			</p>
			<Link asButton href="/" class="mx-auto mt-12 max-2xl:!px-4">
				{nbspify("Zpět na hlavní stránku")}
			</Link>
		</div>
	</section>
</Layout>
